import React, {useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {useSearchParams, useNavigate} from 'react-router-dom';
import storage from '../utils/storage';

export default function YoutubeCallbackPage() {
	const [state, setState] = useState({
		loading: true,
		error: null
	});
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	
	useEffect(() => {
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/youtube/callback`;
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({code: searchParams.get('code')})
				});
				const data = await response.json();
				
				if (data.error) {
					setState({loading: false, error: data.error});
				} else {
					storage.setItem('token', data.token);
					navigate(`/form/${encodeURIComponent(searchParams.get('state'))}`);
				}
			} catch (e) {
				console.log(e);
				setState({loading: false, error: 'Something went wrong. Please try again later'});
			}
		})();
	}, []);
	
	return (
		<>
			{state.loading && <CircularProgress color={'secondary'}/>}
			{state.error && <Typography variant={'h4'} color={'secondary'}>{state.error}</Typography>}
		</>
	);
}

YoutubeCallbackPage.propTypes = {}