import React, {useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import ContactForm from '../components/ContactForm';
import storage from '../utils/storage';

export default function ContactFormPage() {
	const {formId} = useParams();
	const [state, setState] = useState({
		loading: true,
		error: null,
		form: null,
		token: storage.getItem('token')
	});
	
	useEffect(() => {
		(async () => {
			const s = {...state, loading: false};
			
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/form/${formId}`;
				const response = await fetch(url);
				const form = await response.json();
				
				if (form.error) {
					setState({...s, error: form.error});
				} else {
					setState({...s, form});
				}
			} catch (e) {
				console.log(e);
				setState({...s, error: 'Something went wrong. Please try again later'});
			}
		})();
	}, []);
	
	return (
		<>
			{state.loading && <CircularProgress color={'secondary'}/>}
			{state.form && <ContactForm form={state.form} token={state.token} onError={msg => setState({...state, error: msg})}/>}
			{state.error && <Typography variant={'h5'} color={'secondary'} mt={5} textAlign={'center'}>{state.error}</Typography>}
		</>
	);
}

ContactForm.propTypes = {}