import React, {useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {MuiTelInput} from 'mui-tel-input'
import storage from '../utils/storage';
import {useTheme} from '@emotion/react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

export default function ScreenMessage(props) {
	const form = props.form;
	const token = props.token;
	
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	
	const [formData, setFormData] = useState({});
	const theme = useTheme();
	const navigate = useNavigate();
	
	const handleSubmit = () => {
		
		if (!checked) {
			props.onError('You must confirm eligibility, tax responsibility, and release liability.');
			return;
		}
		
		props.onError(null);
		setLoading(true);
		
		(async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_SERVER}/form/${encodeURIComponent(form._id)}/message`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						token,
						form: formData
					})
				});
				setLoading(false);
				
				const data = await response.json();
				if (data.error) {
					return props.onError(data.error);
				} else {
					return navigate('/thank-you');
				}
				
			} catch (e) {
				console.log(e);
				setLoading(false);
				return props.onError('Something went wrong. Please try again later.');
			}
		})();
	}
	
	const onCancel = () => {
		storage.removeItem('token');
		window.location.reload();
	};
	
	return (
		<Paper>
			<Box p={4}>
				<ValidatorForm className={'form'} noValidate onSubmit={() => handleSubmit()}>
					<Grid container spacing={2} alignItems={'top'}>
						{form.description && <Grid item xs={12}><Typography textAlign={'center'}>{form.description}</Typography></Grid>}
						<Grid item xs={4}>
							<TextValidator
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='firstName'
								label='First Name'
								name='firstName'
								autoComplete='firstName'
								autoFocus
								value={formData.firstName || ''}
								validators={['required']}
								errorMessages={['this field is required']}
								onChange={event => setFormData({...formData, firstName: event.target.value})}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextValidator
								variant='outlined'
								margin='normal'
								fullWidth
								id='middleName'
								label='Middle Name'
								name='middleName'
								autoComplete='middleName'
								value={formData.middleName || ''}
								onChange={event => setFormData({...formData, middleName: event.target.value})}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextValidator
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='lastName'
								label='Last Name'
								name='lastName'
								autoComplete='lastName'
								value={formData.lastName || ''}
								validators={['required']}
								errorMessages={['this field is required']}
								onChange={event => setFormData({...formData, lastName: event.target.value})}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='email'
								label='Email Address'
								name='email'
								autoComplete='email'
								value={formData.email || ''}
								validators={['required', 'isEmail']}
								errorMessages={['this field is required', 'email is not valid']}
								onChange={event => setFormData({...formData, email: event.target.value})}
							/>
						</Grid>
						<Grid item xs={6}>
							<MuiTelInput
								variant='outlined'
								margin='normal'
								fullWidth
								id='phone'
								label='Phone Number'
								name='phone'
								autoComplete='phone'
								defaultCountry={'US'}
								value={formData.phone || ''}
								onChange={p => setFormData({...formData, phone: p})}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextValidator
								variant='outlined'
								margin='normal'
								fullWidth
								multiline
								minRows={3}
								maxRows={10}
								required
								id='message'
								label='Your Message'
								name='message'
								value={formData.message || ''}
								validators={['required', 'minStringLength:20', 'maxStringLength:5000']}
								errorMessages={['this field is required', 'message is too short', 'maximum message length is 5000 characters']}
								onChange={event => setFormData({...formData, message: event.target.value})}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel control={<Checkbox
								checked={checked}
								onChange={e => setChecked(e.target.checked)}
							/>} label="Consumer confirms eligibility, tax responsibility, and release liability."/>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<LoadingButton
								disabled={!checked}
								loading={loading}
								color='primary'
								variant='contained'
								type={'submit'}
							>Submit</LoadingButton>
							
							<Button
								style={{marginLeft: theme.spacing(1)}}
								color={'error'}
								variant='contained'
								onClick={onCancel}>Cancel</Button>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Box>
		</Paper>
	);
}

ScreenMessage.propTypes = {
	form: PropTypes.object.isRequired,
	token: PropTypes.string.isRequired,
	onError: PropTypes.func.isRequired
}